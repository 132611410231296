import { cls } from '@/utils'
import IconNSFW from '@haiper/icons-svg/icons/outline/error.svg'
import Button from '@/components/button'
import { PoNVoid } from '@/types'

export interface NSFWProps {
  className?: string
  onView?: (e?: any) => PoNVoid
  outputType: 'video' | 'image'
}

export default function NSFW({ className, onView, outputType }: NSFWProps) {
  return (
    <div
      className={cls(
        'absolute inset-0 flex justify-center items-center z-30 text-text backdrop-blur-[50px] rounded-[6px] pointer-events-none bg-surface border',
        className,
      )}
      aria-label='nsfw mask'
    >
      <div className='flex flex-col gap-2 md:gap-4 items-center justify-between' aria-label='nsfw-mask-inner'>
        <IconNSFW alt='eye-slash' className='size-12 text-icon' />
        <div className='text-body-md tracking-15 text-center'>Your creation may contain sensitive content</div>
        <Button
          variant='transparent'
          className='rounded-md border border-solid border-border pointer-events-auto text-body-md h-9 md:h-10'
          onClick={onView}
        >
          {/* Watch {outputType === 'video' ? 'video' : 'images'} */}
          Show
        </Button>
      </div>
    </div>
  )
}
